import React from 'react';
import styled from 'styled-components';
import { Grey, Spacer } from '@components/utils';

export default ({ image, width, caption }) => {
  return (
    <>
      <Spacer size={14} md={10} sm={8} />
      <PortfolioImageStyled>
        <img src={image} width={width} />
        <div>
          {caption ? (
            <Grey className="portfolio-image-caption" style={{ maxWidth: width }}>
              {caption}
            </Grey>
          ) : null}
        </div>
      </PortfolioImageStyled>
    </>
  );
};

export const PortfolioImageStyled = styled.div`
  text-align: center;
  line-height: 1;

  .portfolio-image-caption {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
    text-align: left;
    line-height: 1.6;

    @media (max-width: 600px) {
      margin-top: 10px;
    }
  }
`;
