import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import PortfolioHero from '@components/portfolio-hero';
import PortfolioDetails from '@components/portfolio-details';
import PortfolioClose from '@components/portfolio-close';
import PortfolioNext from '@components/portfolio-next';
import Container from '@components/container';
import { useNavigateState } from '@components/navigate';
import { Spacer } from '@components/utils';

export default ({ project, nextProject }) => {
  const [scroll, setScroll] = useState(0);
  const { active } = useNavigateState();

  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const initialAnimation = useSpring({
    from: {
      opacity: 0,
      translate: -10,
    },
    to: {
      opacity: active ? 1 : 0,
      translate: active ? 0 : -10,
    },
    duration: 300,
  });

  const { translate: initialTranslate, ...restInitialAnimation } = initialAnimation;

  return (
    <main>
      <animated.div
        style={{ ...restInitialAnimation, transform: initialTranslate.interpolate(x => `translate3d(0,${x}px,0)`) }}
      >
        <PortfolioHero project={project} preferExpandedHero={true} disableLink={true} />
        <Container className="container">
          <PortfolioDetails project={project} />
        </Container>
        <Spacer size={14} md={10} sm={8} />
        <PortfolioNext {...nextProject} />
      </animated.div>
      <PortfolioClose scroll={scroll} visible={active} />
    </main>
  );
};
