import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';
import { projectSelector } from '@selectors/project';
import { metaSelector } from '@selectors/meta';
import { aboutMeSelector, linksSelector } from '@selectors/about';
import ProjectExpanded from '@components/project-expanded';
import Footer from '@components/footer';
import FadeTransition from '@components/fade-transition';
import Nav from '@components/nav';
import NavSticky from '@components/nav-sticky';

export default ({ data, pageContext }) => {
  const { slug, title, description, shareImage } = pageContext;
  const about = aboutMeSelector(data);
  const links = linksSelector(data);
  const projects = projectSelector(data);
  const project = projects.find(p => p.slug === slug);
  const nextProject = projects.find(p => p.index === project.index + 1) || projects[0];
  const {
    title: genericTitle,
    description: genericDescription,
    keywords,
    shareImage: genericShareImage,
  } = metaSelector(data);

  return (
    <Layout
      title={`${title} | ${genericTitle}`}
      description={description || genericDescription}
      shareImage={shareImage || genericShareImage}
      keywords={keywords}
    >
      <div>
        <Nav about={about} dark={project.darkNavigation} />
        <NavSticky about={about} />
        <ProjectExpanded key={project.index} project={project} nextProject={nextProject} />
      </div>
      <FadeTransition>
        <Footer links={links} about={about} />
      </FadeTransition>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulMeta {
      edges {
        node {
          title
          keywords {
            id
            keywords
          }
          description {
            id
            description
          }
          shareImage {
            fixed(width: 628) {
              src
            }
          }
        }
      }
    }
    allContentfulServices {
      edges {
        node {
          name
        }
      }
    }
    allContentfulAboutMeLinks {
      edges {
        node {
          name
          url
        }
      }
    }
    allContentfulAboutMe(limit: 1) {
      edges {
        node {
          headline
          subHeadline
          contact
          email
          phone
          portrait {
            file {
              url
            }
          }
          childContentfulAboutMeRecentPartnersTextNode {
            internal {
              content
            }
          }
          childContentfulAboutMeBioRichTextNode {
            json
          }
        }
      }
    }
    allContentfulProject(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          title
          type
          position
          darkNavigation
          description {
            id
            description
          }
          metadata {
            id
            metadata
          }
          credits {
            id
            credits
          }
          hero {
            fixed(width: 2340) {
              src
            }
          }
          expandedHero {
            fixed(width: 2340) {
              src
            }
          }
          details {
            __typename
            ... on ContentfulSingleImage {
              caption
              width
              image {
                file {
                  url
                }
              }
            }
            ... on ContentfulMultipleImages {
              images {
                file {
                  url
                }
              }
            }
            ... on ContentfulQuote {
              quote {
                quote
              }
            }
            ... on ContentfulBreakdown {
              title
              body {
                body
              }
              width
              align
              image {
                file {
                  url
                }
              }
              videoThumbnail {
                file {
                  url
                }
              }
            }
            ... on ContentfulVideo {
              caption
              width
              video {
                id
                file {
                  url
                  fileName
                  contentType
                }
              }
              videoThumbnail {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
