import React from 'react';
import styled from 'styled-components';
import { Spacer } from '@components/utils';

export default ({ quote }) => {
  return (
    <>
      <Spacer size={14} md={10} sm={8} />
      <PortfolioQuote>{quote}</PortfolioQuote>
    </>
  );
};

export const PortfolioQuote = styled.h3`
  text-align: center;
  font-size: 60px;
  line-height: 1.3;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 50px;
  }

  @media (max-width: 600px) {
    font-size: 40px;
  }
`;
