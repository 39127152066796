import React from 'react';
import styled from 'styled-components';
import Container from '@components/container';
import { Spacer } from '@components/utils';
import Link from '@components/link';

export default ({ title, slug, hero }) => {
  return (
    <HeroWrap>
      <Container>
        <Spacer size={13} md={9.3} sm={8} />
        Up next
        <Spacer size={2} sm={0} />
        <Hero>{title}</Hero>
        <Spacer size={13} md={9.5} sm={8} />
      </Container>
      <Link url={`/${slug}/`} noHover style={{ display: 'block' }}>
        <Img src={hero} width="100%" alt="poster" style={{ display: 'block' }} />
      </Link>
    </HeroWrap>
  );
};

export const Hero = styled.h2`
  font-size: 60px;
  margin: 0;
  line-height: 1.2;

  @media (max-width: 1000px) {
    font-size: 44px;
  }

  @media (max-width: 600px) {
    font-size: 38px;
    margin-top: 30px;
  }
`;

export const Img = styled.img`
  cursor: pointer;
`;

export const HeroWrap = styled.div`
  background: #17222b;
`;
