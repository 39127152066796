import React from 'react';
import styled, { css } from 'styled-components';
import { Grey, Spacer } from '@components/utils';

export default ({ project }) => {
  return (
    <>
      <Spacer size={5.5} />
      <PortfolioAbout hasCredits={!!project.credits}>
        <div className="portfolio-about-left">
          {project.title}
          <Spacer size={1} />
          <Grey>
            <ul>
              <li>{project.type}</li>
              {project.metadata && project.metadata.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
          </Grey>
        </div>
        <div className="portfolio-about-center">
          <Grey>
            <p>{project.description}</p>
          </Grey>
        </div>
        <div className="portfolio-about-right">
          <ul>
            {project.credits &&
              project.credits.map(([role, name], index) => (
                <li key={index}>
                  <Grey>
                    {role}
                    {name ? ':' : ''}
                  </Grey>{' '}
                  {name ? name : ''}
                </li>
              ))}
          </ul>
        </div>
      </PortfolioAbout>
    </>
  );
};

export const PortfolioAbout = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .portfolio-about-left {
    width: 30%;
  }

  .portfolio-about-center {
    width: 50%;
    padding: 0 70px 0 30px;

    ${({ hasCredits }) =>
      !hasCredits &&
      css`
        width: 70%;
        padding-right: 0;
      `}
  }

  .portfolio-about-right {
    width: 20%;

    ${({ hasCredits }) =>
      !hasCredits &&
      css`
        display: none;
      `}
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0;
    margin: 0;
  }

  li + li {
    margin-top: 3px;
  }

  @media (max-width: 1000px) {
    .portfolio-about-center {
      width: 70%;
      padding-right: 0;
    }

    .portfolio-about-right {
      width: 100%;
      margin-top: 0;
    }
  }

  @media (max-width: 600px) {
    .portfolio-about-left,
    .portfolio-about-center {
      width: 100%;
      padding: 0;
    }

    .portfolio-about-center,
    .portfolio-about-right {
      margin-top: 20px;
    }
  }
`;
