import React from 'react';
import styled, { css } from 'styled-components';
import { Spacer } from '@components/utils';

export default ({ images }) => {
  return (
    <>
      <Spacer size={14} md={10} sm={8} />
      <PortfolioImages size={images.length}>
        {images.map((image, index) => {
          return (
            <div className="image-wrap" key={index}>
              <div>
                <img src={image} width="100%" />
              </div>
            </div>
          );
        })}
      </PortfolioImages>
    </>
  );
};

interface PortfolioImagesProps {
  size: number;
}

export const PortfolioImages = styled.div<PortfolioImagesProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  .image-wrap {
    padding: 0 40px;

    width: ${({ size }) =>
      css`
        ${100 / size}%
      `};
  }

  @media (max-width: 1000px) {
    .image-wrap {
      padding: 0 10px;
    }
  }

  @media (max-width: 600px) {
    .image-wrap {
      padding: 0;
      width: 100%;
    }

    .image-wrap + .image-wrap {
      margin-top: 60px;
    }
  }
`;
