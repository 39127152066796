import React, { useState } from 'react';
import Sensor from 'react-visibility-sensor';
import { useSpring, animated } from 'react-spring';
import { isMobile } from '../lib/mobile';
import { Relative } from '@components/utils';

export default ({ children }) => {
  const [visible, setVisible] = useState(false);
  const animation = useSpring({
    from: {
      opacity: 0,
      translate: 50,
    },
    to: {
      opacity: visible || isMobile() ? 1 : 0,
      translate: visible || isMobile() ? 0 : 50,
    },
    duration: 500,
  });

  const onChange = visible => {
    if (visible) setVisible(true);
  };

  const { translate, ...rest } = animation;

  return (
    <Relative>
      <>
        {!visible ? (
          <>
            <Sensor onChange={onChange} delayedCall={true} scrollCheck={true}>
              <span style={{ position: 'absolute', height: '200px', width: '100%' }} />
            </Sensor>
            <Sensor onChange={onChange} delayedCall={true} scrollCheck={true}>
              <span style={{ position: 'absolute', height: '200px', width: '100%', bottom: '0' }} />
            </Sensor>
          </>
        ) : null}
        <animated.div style={{ ...rest, transform: translate.interpolate(x => `translate3d(0,${x}px,0)`) }}>
          {children}
        </animated.div>
      </>
    </Relative>
  );
};
