import React from 'react';
import styled, { css } from 'styled-components';
import { Grey, Spacer } from '@components/utils';
import Video from '@components/portfolio-video';

export default ({ image, title, body, align, width, poster }) => {
  return (
    <>
      <Spacer size={14} md={10} sm={8} />
      <PortfolioBreakdown align={align} width={width}>
        <div className="portfolio-breakdown-left">
          <div>
            {image.endsWith('.mp4') ? (
              <Video video={image} width={width} removeSpacer={true} poster={poster} playsInline />
            ) : (
              <img src={image} width="100%" />
            )}
          </div>
        </div>
        <div className="portfolio-breakdown-right">
          {title}
          <Spacer size={1.5} md={0.5} sm={0.5} />
          <Grey>{body}</Grey>
        </div>
      </PortfolioBreakdown>
    </>
  );
};

const PortfolioBreakdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 100%;

  .portfolio-breakdown-left {
    width: 50%;
    padding-right: 50px;
  }

  .portfolio-breakdown-right {
    width: 50%;
    padding-left: 50px;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `};

  ${({ align }) =>
    align === 'right' &&
    css`
      .portfolio-breakdown-left {
        order: 2;
        padding-right: 0;
        padding-left: 50px;
      }

      .portfolio-breakdown-right {
        padding-right: 50px;
        padding-left: 0;
      }
    `};

  @media (max-width: 1000px) {
    .portfolio-breakdown-right,
    .portfolio-breakdown-left {
      order: 1;
      width: 100%;
      padding: 0;
    }

    .portfolio-breakdown-right {
      margin-top: 20px;
    }
  }
`;
