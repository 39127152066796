import React from 'react';

import Reveal from '@components/reveal';
import PortfolioAbout from '@components/portfolio-about';
import PortfolioImage from '@components/portfolio-image';
import PortfolioImages from '@components/portfolio-images';
import PortfolioQuote from '@components/portfolio-quote';
import PortfolioBreakdown from '@components/portfolio-breakdown';
import PortfolioVideo from '@components/portfolio-video';
import { Spacer } from '@components/utils';

export default ({ project }) => {
  return (
    <div>
      <PortfolioAbout project={project} />
      {project.details.map((detail, index) => {
        if (detail.type === 'MultipleImages') {
          return (
            <Reveal key={index}>
              <PortfolioImages {...detail.data} key={index} />
            </Reveal>
          );
        }
        if (detail.type === 'SingleImage') {
          return (
            <Reveal key={index}>
              <PortfolioImage {...detail.data} key={index} />
            </Reveal>
          );
        }
        if (detail.type === 'Quote') {
          return (
            <Reveal key={index}>
              <PortfolioQuote {...detail.data} key={index} />
            </Reveal>
          );
        }
        if (detail.type === 'Breakdown') {
          return (
            <Reveal key={index}>
              <PortfolioBreakdown {...detail.data} key={index} />
            </Reveal>
          );
        }
        if (detail.type === 'Video') {
          return (
            <Reveal key={index}>
              <PortfolioVideo {...detail.data} key={index} />
            </Reveal>
          );
        }
      })}
    </div>
  );
};
