import React from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from '../lib/mobile';
import Link from '@components/link';

export default ({ scroll, visible }) => {
  const style = {
    transform: `rotate(${isMobile() ? 0 : scroll / 4}deg)`,
  };
  return (
    <Close url="/" style={style} visible={visible} noHover>
      Back
    </Close>
  );
};

const Close = styled(Link)`
  line-height: 1;
  display: inline-block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5000;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9fb1ba;
  line-height: 1;
  cursor: pointer;
  border-radius: 50%;
  color: #111;
  transform: translateZ(0);
  transition: opacity 0.5s;
  pointer-events: none;
  opacity: 0;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */

  ${({ visible }) =>
    visible &&
    css`
      pointer-events: all;
      opacity: 1;
    `};
`;
