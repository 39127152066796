import React, { useRef } from 'react';
import Sensor from 'react-visibility-sensor';
import { Grey, Spacer } from '@components/utils';
import { PortfolioImageStyled } from '@components/portfolio-image';

export default ({ video, width, caption, removeSpacer, poster }) => {
  const ref = useRef(null);
  const onChange = visible => {
    try {
      if (visible) {
        ref.current.play();
      } else {
        ref.current.pause();
      }
    } catch (e) {}
  };
  return (
    <>
      {removeSpacer ? null : <Spacer size={14} md={10} sm={8} />}
      <PortfolioImageStyled>
        <Sensor onChange={onChange} scrollCheck={true} partialVisibility={true}>
          <video src={video} width={width} ref={ref} loop={true} poster={poster} playsInline />
        </Sensor>
        <div>
          {caption ? (
            <Grey className="portfolio-image-caption" style={{ maxWidth: width }}>
              {caption}
            </Grey>
          ) : null}
        </div>
      </PortfolioImageStyled>
    </>
  );
};
